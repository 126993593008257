import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/menus/"
      }}>{`Navigation menus`}</a>{` are an essential part of every website. They're usually
placed in the header and provide an overview of a website's page hierarchy. They enable users to gain an overview of
the content and to quickly navigate to main sections of the website.`}</p>
    <p>{`Many websites use fly-out (or drop-down) menus, where certain menu items open submenus on hover or click. Often, these
menus offer very poor accessibility for screen reader and keyboard users. But we can do better!`}</p>
    <p>{`I'll show you how to leverage the power of modern web features like the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` to easily create an accessible fly-out menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFqzCYyCf/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMTBBH/2gAIAQEAAQUCRUaVtnD1GU1+IW0hAB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8BjX//xAAcEAABBAMBAAAAAAAAAAAAAAAAAQISIRExQXH/2gAIAQEABj8C74LJxTSebLRDR//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/IaGkOwYvIcXLXX9g7+VPIAM3kq0f/9oADAMBAAIAAwAAABAAz//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxBwn//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8Qpsmj/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARQSExkVFhgaHh/9oACAEBAAE/EBw9EGqU7vE0aJpuDAHM2lepW+ohKm42BivEFrUsGGLLkxr8n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A hand holding up a compass for navigation. A rocky coast with trees is visible in the background.",
          "title": "A hand holding up a compass for navigation. A rocky coast with trees is visible in the background.",
          "src": "/static/e9cfc3e8c39caf2282ab761716c4782d/e5166/pexels-valentinantonucci-compass.jpg",
          "srcSet": ["/static/e9cfc3e8c39caf2282ab761716c4782d/f93b5/pexels-valentinantonucci-compass.jpg 300w", "/static/e9cfc3e8c39caf2282ab761716c4782d/b4294/pexels-valentinantonucci-compass.jpg 600w", "/static/e9cfc3e8c39caf2282ab761716c4782d/e5166/pexels-valentinantonucci-compass.jpg 1200w", "/static/e9cfc3e8c39caf2282ab761716c4782d/b17f8/pexels-valentinantonucci-compass.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Valentin Antonucci / pexels.com`}</em></p>
    <h2>{`What we want to achieve`}</h2>
    <p>{`Our navigation menu should be easy to use for different user groups:`}</p>
    <ul>
      <li parentName="ul">{`The submenus should appear `}<strong>{`on top of the site content`}</strong>{` and should not disappear immediately after
the mouse has left the clickable area.`}</li>
      <li parentName="ul">{`Screen reader users require `}<strong>{`meaningful markup`}</strong>{` to understand and operate the navigation menu.`}</li>
      <li parentName="ul">{`Keyboard users should be able to operate the menu `}<strong>{`using the keyboard alone`}</strong>{`.`}</li>
    </ul>
    <h2>{`Demo: Accessible Navigation Menu`}</h2>
    <p>{`I've created a `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/full/ExqWMpb"
      }}>{`CodePen demo`}</a>{` of an accessible navigation menu using the
Popover API. Be sure to open the demo on a desktop screen or tablet in landscape mode to see the fly-out menu. On smaller
devices, the demo will display a mobile menu instead.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAABf0lEQVQoz51SCW6DMBD0/39XpeWIweGMCcZcpoQrTGW30KSNVKkrjVgx3tnZtYlIJejLCYnHQQ8B2uYdWV7gIkoMw4i6rjGNI9q2RVFIjF952yrcx7qu5ktWrIhziTgvIFWHs5DgRbUfuN1W9MOEebk9CEzLgn6ccB0nTPOyixLd0XaPBkEQYFmWL6FPASlLHN4sHKln+DAM0SmF7JLDclzDxel5P0+maQI7BaA+Q5Sk+BllWcE5UiRJbMZXShknhZRwqYcwjDAOw/fI8zzD9Xx47ITkzNH3vSnSGIYBdd0YFzzLHhqlnONg2cZMHMcG1+sAsswzWBgZHH2GsqrQdd0uWFU1LNsB59nDKoTIcWIMaZKgaRpTY3aoScYYXt8sOI5rhO6jqmvYLjUO9Hr+CqL3wvkZIs9NF31J2pmGzt+7DqWUkFL+4p+BaHeUUgghTIFuoEfYoN+cUp3hdH7PPQPxfR+2bSPLLnvRdikbPh/y7//PQKIoghbdHG7F/8UHqjBHeOrk0+gAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot of the accessible navigation menu demo page. The submenu of the menu item solutions is open.",
          "title": "Screenshot of the accessible navigation menu demo page. The submenu of the menu item solutions is open.",
          "src": "/static/84ff8009114bb2d0138210e82003075e/c1b63/screenshot_accesible_nav_menu.png",
          "srcSet": ["/static/84ff8009114bb2d0138210e82003075e/5a46d/screenshot_accesible_nav_menu.png 300w", "/static/84ff8009114bb2d0138210e82003075e/0a47e/screenshot_accesible_nav_menu.png 600w", "/static/84ff8009114bb2d0138210e82003075e/c1b63/screenshot_accesible_nav_menu.png 1200w", "/static/84ff8009114bb2d0138210e82003075e/bb3b7/screenshot_accesible_nav_menu.png 1211w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Alexander Lehner`}</em></p>
    <p>{`The navigation menu is easy to use for mouse, keyboard, and screen reader users. Let's examine the key elements.`}</p>
    <h3>{`Meaningful Markup`}</h3>
    <p>{`We use the `}<InlineCode mdxType="InlineCode">{`nav`}</InlineCode>{` element to convey the role of the navigation menu. The menu items are placed
inside an unordered list (`}<InlineCode mdxType="InlineCode">{`ul`}</InlineCode>{` element). This allows assistive technologies to announce the number
of items in the menu and provide corresponding navigation functionality.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<nav class="main-nav">
    <ul>
        <li>
            <button popovertarget="nav-panel-solutions" type="button">Solutions</button>
            <div id="nav-panel-solutions" popover>...</div>
        </li>
        <li><a href="#">Products</a></li>
        <li>...</li>
        <li>...</li>
    </ul>
</nav>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The list items either contain a direct link to a subpage, or they contain a `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element
that opens a submenu which uses the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute. The button is connected to the popover
content via the `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` attribute.`}</p>
    <p>{`One of the great advantages of the Popover API: It automatically conveys to assistive technologies whether the
submenu navigation is presently collapsed or expanded. You should read the article
`}<a parentName="p" {...{
        "href": "https://hidde.blog/popover-accessibility/"
      }}>{`“On popover accessibility: what the browser does and doesn’t do”`}</a>{`
for more information.`}</p>
    <h3>{`Avoid the `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{` role`}</h3>
    <p>{`As you can see in my demo, I'm not using the `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`menuitem`}</InlineCode>{` roles
in my navigation menu. This is on purpose! These ARIA roles are only inteded for menu widgets that behave like native
operating system menus.`}</p>
    <p>{`Go read the article `}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2017/10/dont-use-aria-menu-roles-for-site-nav.html"
      }}>{`“Don’t Use ARIA Menu Roles for Site Nav”`}</a>{`
by Adrian Roselli for a detailed explanation.`}</p>
    <h3>{`Visual Placement`}</h3>
    <p>{`When a submenu is opened, it will automatically appear on the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`top layer`}</a>{`,
a separate layer that sits on top of all other layers displayed in the web document. You only need to take care of the
placement and size of the submenu:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`header nav.main-nav [popover] {
    top: var(--header-height);
    margin: 0;
    width: 100%;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The default placement of popover content is in the center of the screen. To place our submenus right beneath the header,
we set `}<InlineCode mdxType="InlineCode">{`top`}</InlineCode>{` to the height of the header and remove any `}<InlineCode mdxType="InlineCode">{`margin`}</InlineCode>{`. Next, we
set `}<InlineCode mdxType="InlineCode">{`width`}</InlineCode>{` to `}<InlineCode mdxType="InlineCode">{`100%`}</InlineCode>{` to make the submenu cover the whole width of the page.`}</p>
    <h3>{`Keyboard Operability`}</h3>
    <p>{`You can use the `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` key to focus the links and buttons in the navigation menu. After opening a
submenu, its content will be next in the focus order. Pressing the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key will close the submenu
and automatically return focus to the trigger button. All thanks to the mighty Popover API! 🤩`}</p>
    <h3>{`Light-dismiss of Submenus`}</h3>
    <p>{`Another baked in feature of the Popover API is light-dismiss: Clicking outside of the submenu area will close the submenu
and return focus to the trigger element. No need to mess around with `}<InlineCode mdxType="InlineCode">{`focusout`}</InlineCode>{` events in JavaScript.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/menus/"
        }}>{`Navigation Menus Tutorial (W3C)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/"
        }}>{`Disclosure Pattern (ARIA APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation/"
        }}>{`Example Disclosure Navigation Menu (ARIA APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      